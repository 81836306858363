.ReferalHistory {
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  //   padding: 24px;

  &-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px;
    padding-bottom: 8px;

    &-left {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      color: #2c6a61;
    }
    &-right {
      display: flex;
      background: white;
      align-items: center;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      /* identical to box height, or 21px */

      color: #2c6a61;
    }
    &-right img {
      margin-right: 6px;
    }
    &-right label {
      margin-top: 3px;
    }
  }

  &-Contents {
    // margin-top: 32px;
    width: 100%;
    background: #fff;
    // display: flex;
    // flex-direction: column;
    // min-height: 67vh;
    // max-height: 67vh;
    min-height: 65vh;
    max-height: auto;
    // overflow-y: scroll;

    @media (max-height: 815px) {
      min-height: 59vh;
      max-height: 59vh;
    }

    @media (max-height: 700px) {
      min-height: 55vh;
      max-height: 55vh;
    }

    @media (max-height: 667px) {
      min-height: 48vh;
      max-height: 48vh;
    }

    @media (max-height: 570px) {
      min-height: 41vh;
      max-height: 41vh;
    }

    &-Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 24px;
      padding: 24px 0;
      border-bottom: 1px solid #e5e5e5;

      &-TextContents {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        &-Name {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 130%;

          color: #000000;
        }

        &-Number {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          margin-top: 5px;
          color: #555555;
        }
      }
      &-Button {
        background: #ffffff;
        border: 1px solid #777777;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        padding: 8px 16px;
        color: #666666;
        width: 30%;
      }
    }
  }
}
