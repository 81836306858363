.MyRewardDashboard {
  margin: 80px 24px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  height: 126px;

  &-Item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    &-Row1 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      color: #ffffff;
    }
    &-Row2 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      margin-top: 4px;
      letter-spacing: 2px;
      color: #ffffff;
    }
  }

  &-rewards-earned {
    background: #45a145;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &-heading {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: #ffffff;
    }

    &-revenue {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 150%;
      /* identical to box height, or 54px */

      text-align: center;

      color: #ffffff;
    }
  }
}
