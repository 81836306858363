

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Book.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Black.woff') format('woff');
}


.ReferWinReward {
  display: flex;
  background: #fff9eb;
  border-radius: 15px 15px 0px 0px;
  flex-direction: column;
  margin-top: -2.4rem;
  padding: 4rem 2.4rem 6rem 2.4rem;
  width: 100%;

  &-TopCard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-Contents {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      &-Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        line-height: 130%;
        // color: #2c6a61;
      }
      &-SubText {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 120%;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.7);
      }
      &-Button {
        background: #2c6a61;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 1rem 0rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 20px;
        margin-top: 16px;
        color: #ffffff;
        outline: none;
        border: none;
        width: 65%;
      }
    }
  }

  &-partners-earning {
    border: 1px solid #2c6a61;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 2.5rem;

    &-heading {
      font-family: "Circular Std";
      font-style: normal;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
    }

    &-revenue {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;

      text-align: center;

      color: #2c6a61;
    }

    &-subHeading {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      text-align: center;

      color: rgba(0, 0, 0, 0.6);

      &-amount {
        color: #df9900;
      }
    }

    &-cta {
      background: #2C6A61;
box-shadow: 0px 4px 16px rgba(44, 106, 97, 0.24);
border-radius: 4px;
border: none;
font-family: "Circular Std";
font-style: normal;
color: white;
width: 70%;
height: 40px;
font-size: 2rem;
    }
  }

  &-MiddleCard {
    margin-top: 24px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(43, 191, 171, 0.15);
    border-radius: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px;

    &__Img {
      width: 100%;
      margin-top: 2.4rem;
    }

    &-TopSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(44, 106, 97, 0.2);

      &-TextContent {
        display: flex;
        flex-direction: column;
        margin-right: 16px;

        &-Heading {
          font-style: normal;
          font-weight: bold;
          font-size: 2rem;
          line-height: 130%;
          color: #2c6a61;
          width: 110%;
        }
        &-Text {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 120%;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.6);
        }
        &-Button {
          outline: none;
          border: none;
          margin-top: 16px;
          color: #2c6a61;
          border: 1px solid #2c6a61;
          border-radius: 5px;
          padding: 0.8rem 0rem;
          display: flex;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 20px;
          background: #ffffff;
          width: 70%;
        }
      }
    }
    &-MiddleSection {
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr;
      margin-top: 24px;
      // grid-column-gap: 24px;
      display: flex;
      justify-content: space-between;

      &-Item {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }

    &-MiddleSectionNew {
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 2rem;
      grid-column-gap: 6rem;
      margin-top: 1rem;

      &-Item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }
    &-BottomSection {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      margin-top: 24px;
      display: flex;
      justify-content: space-around;

      &-Item {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }
  }
  &-BottomCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    &-TextContent {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      &-Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        line-height: 130%;
        color: #222222;
      }
      &-Text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 130%;
        margin-top: 8px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.7);
      }
      margin-top: 16px;
      &-Button {
        background: #f98d34;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 20px;
        margin-top: 16px;
        color: #ffffff;
        outline: none;
        border: none;
        width: 80%;
      }
    }
  }
}
.MiddleCard-head {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px;
}

.MiddleCard-subhead {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  padding: 4px 0 16px 0;
  margin: 0;
  border-bottom: 1px dashed #e5e5e5;
}

.MiddleCard-grid {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.MiddleCard-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.MiddleCard-row img {
  width: 60px;
  height: 60px;
}

.MiddleCard-text {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.MiddleCard-text1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #3c4852;
}

.MiddleCard-text2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin: 4px 0 0 0;
}

.MiddleCard-text3 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  align-self: center;
  text-align: center;
  margin: 24px 0 0 0;
  color: rgba(0, 0, 0, 0.6);
}

.MiddleCard-button {
  background: #ffffff;
  border: 1px solid #f39821;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #f39821;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
  padding: 12px 0;
  margin-top: 14px;
  outline: none;
}

.MiddleCard-foot {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 16px 0 24px 0;
  color: #808080;
}
