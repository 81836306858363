.ReferModal {
  display: flex;
  flex-direction: column;

  &-Header {
    padding: 1.2rem 2.4rem 2.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-Heading {
      font-style: normal;
      font-weight: 450;
      font-size: 1.8rem;
      line-height: 20px;

      color: rgba(0, 0, 0, 0.87);

      mix-blend-mode: normal;
    }
  }
  &-Content {
    margin: 2.4rem 2.4rem 0rem;
    display: flex;
    flex-direction: column;

    &-Note {
      display: flex;
      justify-content: space-between;
      background: #fffbed;
      border: 0.5px solid rgba(0, 0, 0, 0.24);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1.2rem;

      &-Text {
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 150%;
        margin-left: 12px;

        color: rgba(0, 0, 0, 0.87);
      }
    }

    &-Note img {
      width: 32px;
      height: 32px;
    }

    &-InputFileld {
      display: flex;
      flex-direction: column;
      margin-top: 2.2rem;

      &-Label {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 18px;
        /* identical to box height */

        color: rgba(0, 0, 0, 0.87);

        mix-blend-mode: normal;
      }
      &-Input {
        border: 1px solid rgba(0, 0, 0, 0.24);
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        padding: 1.4rem 1.6rem;
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 18px;
        margin-top: 8px;

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.7);
      }
      &-MobileContent {
        display: flex;
        align-items: center;
        margin-top: 8px;
        width: 100%;

        border: 1px solid rgba(0, 0, 0, 0.24);
        border-radius: 4px;

        &-India {
          display: flex;
          justify-content: center;
          padding: 1.8rem 1.3rem;
          border-right: 1px solid rgba(0, 0, 0, 0.24);
          box-sizing: border-box;
          border-radius: 4px 0 0 4px;
          width: 15%;
        }
        &-Input {
          border: none;
          border-right: 0;
          box-sizing: border-box;
          // border-radius: 0 4px 4px 0;
          padding: 1.35rem 1.2rem;
          font-style: normal;
          font-weight: 450;
          font-size: 1.4rem;
          line-height: 18px;
          width: 85%;
          outline: none;
          display: flex;
          align-items: center;

          color: rgba(0, 0, 0, 0.7);
        }

        &-Contact {
          padding: 1.35rem 1.2rem;
          border: none;

          border-radius: 0 4px 4px 0;

          display: flex;
          align-items: center;

          border-left: 0;

          img {
            height: 18px;
          }
        }
      }
    }
  }
  &-Footer {
    display: flex;
    margin: 3rem 1.4rem 1.2rem;
    justify-content: center;

    &-Btn {
      background: #2c6a61;
      border-radius: 4px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      outline: none;
      align-items: center;
      text-align: right;
      border: none;
      color: #ffffff;
    }
  }
  &-Error {
    font-size: 14px;
    line-height: 20px;
    color: red;
    margin-top: 4px;
  }
}

.FormatModal_FormatModal__1FZHl .FormatModal_Content__18enT {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.FormatModal_FormatModal__1FZHl .FormatModal_Content__18enT::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
