.YourRewardsModal {
  display: flex;
  flex-direction: column;
  background-image: url("../../images/Modal/YourRewardsBg.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  // max-width: 1000px !important;
  // margin: auto;

  &-Header {
    display: flex;
    width: 100%;
    padding: 10px 16px 16px 16px;
    border-bottom: 1px solid #e5e5e5;

    &-Heading {
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;

      color: rgba(0, 0, 0, 0.87);
    }
  }
  &-Container {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;

    &-Item {
      margin: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-TextContent {
        display: flex;
        align-items: center;

        &-Text {
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 18px;
          margin-left: 16px;

          display: flex;
          align-items: center;

          color: rgba(0, 0, 0, 0.87);
        }
      }
      &-Value {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
