.MyRewards {
  background: #fff9eb;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;

  &-Header {
    display: flex;
    align-items: center;
    padding: 14px 24px;
    position: fixed;
    top: 0;
    z-index: 1;
    background: #fff9eb;
    width: 100%;

    &-Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      margin-left: 16px;
      color: #222222;
    }
  }
  &-tnc{
    color: black;
    font-size: 1.8rem;
    text-align: end;
    text-decoration: underline;
    width: 95%;
    height: 20px;
    margin-bottom: 5rem;
  }
}
