.MyRewardCarousel {
  display: flex;
  flex-direction: row;
  // width: 100%;
  margin: 24px !important;
  margin-bottom: 15px !important;

  &-Item {
    background: #55ccbc;
    border-radius: 4px;
    display: flex !important;
    padding: 12px;
    // width: 100%;
    align-items: center;
    height: 72px;

    &-Text {
      margin-left: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}

.slick-dots li button:before {
  color: #eeeeee !important;
}

.slick-dots li.slick-active button:before {
  color: #f8edcc !important;
  font-size: 10px !important;
}
