.ReferSuccessModal {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  background-image: url("../../images/Modal/bgImage.png");
  background-repeat: no-repeat;
  background-position: bottom right;

  &-Image {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    
  }
  &-Heading {
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    /* identical to box height, or 135% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);
  }
  &-Row1 {
    margin-top: 16px;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */
    margin:10px;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
  }
  &-Row2 {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 22px;
    margin-top: 24px;
    margin:10px;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
  }
  &-Footer {
    margin: 80px 16px 16px;
    display: flex;
    justify-content: center;

    &-Btn {
      background: #33cc66;
      border-radius: 4px;
      padding: 12px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      color: #ffffff;
      justify-content: center;
    }
    &-Btn label {
      margin-top: 2px;
      margin-left: 8px;
    }
  }
}
