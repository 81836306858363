.kyc__verification__details__container {
  button,
  .kyc__delete__btn,
  .kyc__upload__button {
    cursor: pointer;
  }

  .text {
    font-family: Circular Std;
    font-style: normal;
    display: flex;
    align-items: center;
  }

  .text1 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  img {
    width: 100%;
    height: 100%;
  }

  input[type="file"] {
    display: none;
  }

  .kyc__webcam__video {
    position: absolute;
    top: -999px;
    z-index: 1000;
  }

  &__header {
    height: 76px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;

    &__title {
      @extend .text;
      font-weight: bold;
      font-size: 20px;
      margin: 24px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.01em;
      /* Neutral/Black */
      color: #333756;
    }
  }

  &__body {
    max-height: 525px;
    &__disclaimer__box {
      margin: 16px 24px 27px 24px;
      background: rgba(255, 153, 33, 0.08);
      border: 1px solid #ff9921;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 23px 38px;
    }

    &__submitted__box {
      margin: 16px 24px 27px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 676px;
      height: 66px;
      background: rgba(0, 154, 224, 0.08);
      border: 1px solid #009ae0;
      box-sizing: border-box;
      border-radius: 4px;

      @include respond(phone) {
        width: 366px;
        padding: 16px 12px;
      }
    }

    .kyc__upload__button {
      background: #ffffff;
      border: 1px solid #009ae0;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: 16px;
      padding: 8px 12px;
      font-weight: 700;
      color: #009ae0;
    }

    .kyc__verification__section1__main__box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .kyc__file__name__holder {
      display: flex;
      align-items: center;
      margin: 0 0 18px 16px;

      p {
        @extend .text;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #508a75;
      }

      .kyc__delete__btn {
        cursor: pointer;
        margin: 0 0 0 21px;
      }
    }

    &__section1,
    &__section2,
    &__section3 {
      display: flex;
      margin: 0 0 0 24px;
      transition: all 0.3s ease-in;

      &__heading {
        @extend .text;
        height: 30px;
        margin: 0 0 4px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
      }

      &__subheading {
        @extend .text;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        margin: 0 0 16px 16px;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &__section2 {
      height: 138px;

      @include respond(phone) {
        height: 150px;
      }
    }

    &__progress__bar {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__progress__line {
      height: 100%;
      border-left: 2px solid #aeaeae;
    }

    &__progress__dot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background: #ffffff;
      border: 2px solid #009ae0;
      border-radius: 50%;
      box-sizing: border-box;

      p {
        @extend .text;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__footer {
    height: 80px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
    

    .save__continue__button {
      padding: 12px 40px;
      margin: 16px 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(phone) {
        width: 366px;
      }

      p {
        @extend .text;
        @extend .text1;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .go__to__ac__details__button {
      width: 200px;
      height: 48px;
      margin: 16px 24px;
      background: #009ae0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(phone) {
        width: 366px;
      }

      p {
        @extend .text;
        @extend .text1;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}

.bg__white {
  background-color: #ffffff;
}

@media( max-width :500px) {
  
  .kyc__responsive {
    max-height: 90% !important;
  }

}