.PartnerBanner {
  background: #55ccbc;
  // background-image: url("../../images/Home/SgHomeBg.gif");
  // background-size: cover;
  // background-position: center;
  width: 100%;
  display: flex;
  margin-top: 5.2rem;
  padding: 3.2rem 0 2.4rem 2.4rem;

  @media (max-width: 330px) {
    padding: 3.2rem 0 2.4rem 0rem;
  }

  &-TextContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    @media (max-width: 330px) {
      margin-left: 4px;
    }

    &-Top {
      font-style: normal;
      font-weight: bold;
      font-size: 2.8rem;
      line-height: 130%;
      color: #ffffff;
      width: 130%;
      margin-top: 4rem;

      @media (max-width: 330px) {
        width: 150%;
      }
    }
    &-Bottom {
      margin-top: 1.6rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 130%;
      color: #ffffff;
      width: 100%;

      @media (max-width: 330px) {
        width: 100%;
      }
    }
  }
}
.home{
  .Carousel-Content{
    .slick-slide > div {
      margin: 0 10px;
    }
    .slick-list {
      margin: 0 -10px;
    }
  }
  
}

